<template>
    <div>
      <Home_navbar HeaderClass="fixed-top"></Home_navbar>
      <!-- Breadcrumbs section starts here  -->
      <section class="breadcrumbs">
        <div class="container">   
          <div class="d-flex justify-content-between align-items-center">
             <h2> Magazines </h2>
            <ol>
              <li><router-link to="/">Home</router-link></li>
              <li>Magazines</li>
            </ol>
          </div>  
        </div>
      </section>

      <!-- ======= Article Section Main & Sidebar ======= -->
      <section class="blog">
        <div class="container">
          <div class="row">
            <!-- The main article section starte here -->
            <div class="col-lg-8">
                <!-- Portfolio Search Box & Add Button-->
                <div>
                  <b-row>
                    <b-col sm="12"><b-form-input v-model="SearchBy" placeholder="Search by magazine name"></b-form-input></b-col>
                    <!-- <b-col sm="4"><b-button>Submit Your Photos</b-button></b-col> -->
                  </b-row>    
                </div>
                <!-- Portfolio card Listing goes here -->
                <div id="ThiraCard" v-for="Data in SearchList" v-bind:key="Data.id">
                  <b-row class="row no-gutters profile-card" >
                    <div class="col-md-4 Thira profile-card-5">
                      <div class="card">
                        <div class="card-img-block">
                          <router-link :to="{name:'magazine-detail', params:{ MagazineId:Data.seq_no}}"><img class="card-img-top" v-bind:src="Data.magazine_photo" alt="Image"></router-link>
                        </div>
                      </div>
                    </div>
                      <!-- <div class="col-md-8 Thira profile-card-5">
                        <div class="card-body event-desc-block">
                          <h5 class="page-title-text text-truncate" style="width: 25rem;"><router-link :to="{name:'magazine-detail', params:{ MagazineId:Data.seq_no}}">{{Data.magazine_name}}</router-link></h5>
                          <p class="card-text text-truncate"><span v-html="Data.magazine_description"></span>                       
                          <div class="read-more">
                            <router-link :to="{name:'magazine-detail', params:{ MagazineId:Data.seq_no}}">Read more</router-link>
                          </div> 
                        </div> 
                      </div> -->
                      <div class="col-md-8 Thira profile-card-5">
                        <div class="card-body event-desc-block">
                          <h5 class="page-title-text text-truncate" style="width: 25rem;"><router-link :to="{name:'magazine-detail', params:{ MagazineId:Data.seq_no}}"><h5>{{Data.magazine_name}}</h5></router-link></h5>
                          <p class="card-text text-truncate" ><span v-html="Data.magazine_description"></span>
                          <div class="read-more">
                            <router-link :to="{name:'magazine-detail', params:{ MagazineId:Data.seq_no}}">Read more</router-link>
                          </div>  
                        </div> 
                      </div>                      
                  </b-row>    
                </div> 
              <b-pagination pills class="mt-5"
                v-model="currentPage"
                :total-rows="TotRows"
                :per-page="PerPage"
                aria-controls="ThiraCard"
                @change="handlePageChange"
                align="center"
                size="md"
              ></b-pagination>
            </div>
            <!-- Article Right side bar section starts here -->
            <div class="col-lg-4">
              <div class="sidebar">
                <!-- sidebar recent posts-->
                <SideRecentPerformers/>
                <!-- sidebar Filter By Performers Category-->
                <SideFilterPerformers/>  
              </div>
            </div>
            <!-- Article Right side bar Ends here -->
          </div><!-- End row -->
         
        </div><!-- End container -->
      </section><!-- End Blog Section -->        

      <!-- ======= Inject Footer section here  ======= -->
      <Home_footer/>
    
    </div><!-- End Main Div  -->
</template>

<script>
import Vue from 'vue'
import Vueaxios from 'vue-axios'
import axios from 'axios'
import { CardPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(Vueaxios,axios)

  export default {
  name:"Magazines",
  metaInfo: {
    title: "Thiraseela :: ",
    titleTemplate: "%s Performing Arts Magazines | Artists Portfolios | Book Stage Shows | Online Program Booking"
  },     
    data() {
      return {
        SearchBy: "",
        RespArray: [],
        // Pagination Variables
        CurrPage: 1,
        MaxPage: 0,
        TotRows: 0,
        PerPage: 50,
        currentPage: 1,
      }
    },
    mounted()
    {
      this.ReadAllagazines()
      this.GetTotRecCnt()
    },
    methods: {

      handlePageChange(value) {
      this.CurrPage = value;
      document.body.scrollIntoView();
      // console.log('this.CurrPage',this.CurrPage)
      if (this.CurrPage > this.MaxPage) 
        {
          this.ReadAllagazines();
          this.MaxPage = this.CurrPage
        }
      // console.log('this.MaxPage',this.MaxPage)
      },

      ReadAllagazines(){
        const REQformData1 = new FormData();
        REQformData1.append('Page_No', this.CurrPage);
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=ReadAllagazines',REQformData1)
        .then((resp)=>{
        // this.RespArray=resp.data.Allagazines;
        resp.data.Allagazines.forEach(element => this.RespArray.push(element))
      })
      },

      GetTotRecCnt(){
        const REQformData = new FormData();
        REQformData.append('table_name', 'magazines_main');          
        Vue.axios.post('https://exploreinformatica.com/thira_php_api_2021.php?action=GetTotRecCnt',REQformData)
        .then((resp)=>{
        const FirstObject = resp.data.GetTotRecCnt[0];
        this.TotRows = FirstObject.Tot_Rec_Cnt;
        // console.log('TotRows',this.TotRows)
      })
      }, 
    },
    computed: 
    {
      RespSearchList: function()
        {
          return this.RespArray.filter((RespArray) => {
          return RespArray.magazine_name.toLowerCase().match(this.SearchBy.toLowerCase())
          });
        },
      SearchList: function()
        {
          return this.RespSearchList.slice(
          (this.currentPage - 1) * this.PerPage,
          this.currentPage * this.PerPage,
          );
        }       
    }
  }
</script>

<style scoped>

.profile-card {
    margin-top:35px;
    box-shadow:0 0 10px rgba(0,0,0,0.63);
    border-radius:5px;
}
.profile-card-5{
    /* box-shadow:0 0 10px rgba(0,0,0,0.63); */
    border-radius:5px;
    height: 202px;
    /* height: 450px; */
    /* height: 475px; */
    /* overflow: hidden;     */
}
.profile-card-5 .card-img-block {
    width: 91%;
    margin: 0 auto;
    position: relative;
    top: -20px;
}
.profile-card-5 .event-desc-block {
  /* border-radius:5px; */
  height: 250px;
  overflow: hidden;

}
.profile-card-5 .card-img-block img{
    border-radius:10px;
    height: 200px;
    overflow: hidden;
}
.profile-card-5 h5{
    color:#e75480;
    font-weight:800;
    font-size:16px;
}
.profile-card-5 p{
    font-size:14px;
    font-weight:300;
}
</style>
